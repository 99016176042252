.home-container {
  width: 100%;
  height: 100vh;
}

.ModelCOntainer {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  align-items: center;
}
