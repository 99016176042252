@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

.Galary-Container {
  width: 100%;
  margin-bottom: 7rem;
}

.Galary-HeaderText {
  padding-left: 30px;
  margin-top: 2.5rem;
  margin-bottom: 4rem;
  font-size: 3em;
  color: #4b4b4b;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.Galary-BodyContainer {
  row-gap: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.itemBox {
  border-radius: 30px;
  width: 500px;
  height: 350px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.Image {
  width: 100%;
  height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.one {
  background-color: #fbf0ff;
}

.two {
  background-color: #dff4ff;
}

.three {
  background-color: #d3d2ff;
}

.four {
  background-color: #e3ffda;
}

.five {
  background-color: #ffe7fb;
}

.six {
  background-color: #e6e6e6;
}
@media screen and (max-width: 768px) {
  .Galary-BodyContainer {
    row-gap: 50px;
    padding: 20px;
  }

  .itemBox {
    height: 280px;
  }

  .Galary-HeaderText {
    padding-left: 0px;
    font-size: 2em;
    margin-bottom: 1rem;
    text-align: center;
  }
}
