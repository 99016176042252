@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
}

.home-containerq {
  width: 100%;
  padding-bottom: 0rem;
}

.body-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
}

.box {
  width: 40vw;
  /* height: 70vh; */
  /* margin-top: 15em; */
  /* padding-left: 30px;
  padding-right: 30px; */
}

.box2 {
  width: 50vw;
  height: 50%;
  /* background-color: #06a5ff; */
  overflow: hidden;
  /* margin-top: 2em; */
  position: relative; /* Ensure it's positioned relative or absolute */
  z-index: 1; /* Set a higher z-index value */
}

.HeadText {
  font-size: 4vw;
  font-weight: 600;
  color: #4b4b4b;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.DescriptionText {
  padding-top: 30px;
  font-size: 1rem;
  font-weight: 600;
  color: #4b4b4b;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  text-align: justify;
}

.Data {
  text-align: center;
}

.ProjectData {
  margin-top: 60px;
  display: flex;
  column-gap: 20px;
  align-items: center;
  padding-left: 2rem;
}

.DataValue {
  font-size: 2.4rem;
  color: #4b4b4b;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.DataText {
  font-size: 1.3rem;
  color: #4b4b4b;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.line {
  height: 85px;
  width: 2px;
  background-color: #a8a8a8;
  content: "";
}

.ButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.Button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #06a5ff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

.Button:hover {
  background-color: #70accf;
}

/* 2nd scroller  */

.ModelCOntainer {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  align-items: center;
}

/* 3nd scroller  */

.ModelCOntainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  align-items: center;
  /* background-color: #ff0606; */
}

@media screen and (max-width: 378px) {
  .home-containerq {
    padding-bottom: 15rem;
  }
}

@media screen and (max-width: 768px) {
  .body-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    flex-direction: column-reverse;
    align-items: center;
  }

  .box {
    margin-top: 0rem;
    width: 100vw;
    height: 70vh;
    /* background-color: #0617ff; */
  }

  .box2 {
    margin-top: 0rem;
    width: 100vw;
    height: 100%;
    /* background-color: #0617ff; */
  }

  .HeadText {
    font-size: 2em;
    text-align: center;
  }

  .DescriptionText {
    font-size: 1rem;
    margin: 0rem 1rem;
  }

  .ProjectData {
    margin-top: 60px;
    display: flex;
    column-gap: 20px;
    align-items: center;
    justify-content: center;
    padding-left: 0rem;
  }
}
