@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

.About-Container {
  width: 100%;
  /* height: 100vh; */
  align-self: center;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.About-BodyContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  column-gap: 10px;
}

.AboutBox {
  width: 45vw;
  padding-left: 30px;
  padding-right: 30px;
}
.AboutBox1 {
  align-content: center;
  width: 48vw;
  height: 40em;
  overflow: hidden;
}

.HeaderText {
  margin-top: 0em;
  margin-bottom: 30px;
  margin-left: 30px;
  font-size: 3em;
  color: #4b4b4b;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.BodyText {
  margin-top: 30px;
  margin-left: 80px;
  font-size: 1.5rem;
  color: #4b4b4b;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

@media screen and (max-width: 768px) {
  .About-BodyContainer {
    margin-top: 0rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .HeaderText {
    margin-bottom: 1rem;
    margin-left: 0px;
    font-size: 2em;
  }

  .About-Container {
    height: 100%;
    width: 100vw;
    margin-top: 0rem;
    column-gap: 1px;
    text-align: center;
  }

  .AboutBox {
    width: 80%;
  }

  .BodyText {
    margin-top: 30px;
    margin-left: 0px;
    font-size: 1em;
    text-align: left;
  }

  .AboutBox1 {
    align-content: center;
    width: 100vw;
    height: 20rem;
    overflow: hidden;
  }
}
