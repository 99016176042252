@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

.Project-Container {
  width: 100%;
}
.Project-HeaderText {
  padding-left: 30px;
  margin-top: 2.5rem;
  margin-bottom: 7rem;
  font-size: 3em;
  color: #4b4b4b;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.Project-BodyContainer {
  display: flex;
  flex-wrap: wrap;
  column-gap: 100px;
  justify-content: center;
}

.ProjectBox {
  border-radius: 5em;
  border: 1px solid #4b4b4b;
  width: 22vw;
  height: 100%;
  overflow: hidden;
  padding-top: 5rem;
  text-align: center;
  padding-bottom: 9em;
  justify-content: center;
}

.ProjectBoxHeader {
  font-size: 2em;
  color: #4b4b4b;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  /* margin-bottom: 5rem; */
}

.ProjectBoxDescription {
  padding: 1rem;
  font-size: 1em;
  color: #4b4b4b;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  text-align: justify;
  margin-top: 3rem;
}

@media screen and (max-width: 768px) {
  .Project-Container {
    width: 100vw;
    text-align: center;
    /* margin-bottom: 30rem; */
  }

  .Project-BodyContainer {
    row-gap: 30px;
  }

  .Project-HeaderText {
    padding-left: 0px;
    margin-top: 10rem;
    margin-bottom: 2.6rem;
    font-size: 2em;
  }

  .ProjectBoxHeader {
    margin-bottom: 2rem;
    font-size: 1.5em;
  }

  .ProjectBox {
    border-radius: 3em;
    border: 1px solid #4b4b4b;
    width: 80vw;
    height: 100%;
    overflow: hidden;
    padding-top: 2rem;
    padding-bottom: 3.5em;
    text-align: center;
  }
}
