@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
}
.NavbarItems {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 2 0px;
  width: 100%;
  height: 4em;
  z-index: 2;
  transition: transform 0.3s ease;
}
.hidden {
  transform: translateY(-100%);
}

.logo img {
  height: 40px;
  margin-left: 5rem;
}

.menu-icon {
  display: none;
}

.nav-menu {
  cursor: pointer;
  list-style: none;
  display: flex;
  margin-right: 4vw;
  padding: 0;
}

.nav-menu li {
  margin-right: 10vw;
}

.nav-menu li:last-child {
  margin-right: 0;
}

.nav-menu li a {
  color: #1e1e1e;
  text-decoration: none;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .menu-icon {
    color: #1e1e1e;
    display: block;
    transition: transform 0.3s ease;
  }

  .NavbarItems {
    position: fixed;
    justify-content: space-around;
    text-align: center;
    transition: transform 0.3s ease;
  }
  .nav-menu {
    cursor: none;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #fff;
    max-height: 0; /* Initially hide the menu */
    overflow: hidden; /* Hide overflow content */
    transition: max-height 0.9s ease; /* Add transition for smooth animation */
  }

  .nav-menu.active {
    display: flex;
    max-height: 500px; /* Set a maximum height for the menu */
  }

  .nav-menu li {
    margin: 10px 0;
  }

  .logo img {
    margin-left: 0rem;
  }
}
